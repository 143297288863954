import {
  A1EnglishTestBusinessVisaSEO,
  A1EnglishTestSEO,
  A1EnglishTestSportspersonVisaSEO,
  A2EnglishTestSEO,
  B1EnglishTestHealthCareWorkerVisaSEO,
  B1EnglishTestHighPotentialIndividualVisaSEO,
  B1EnglishTestInternationalAgreementVisaSEO,
  B1EnglishTestNaturalizationSEO,
  B1EnglishTestScaleUpWorkerVisaSEO,
  B1EnglishTestSEO,
  B1EnglishTestSkilledWorkerVisaSEO,
  B1EnglishTestStudentVisaBelowDegreeLevelSEO,
  B2EnglishTestInnovatorFounderVisaSEO,
  B2EnglishTestMinisterOfReligionVisaSEO,
  B2EnglishTestStartUpVisaSEO,
  B2EnglishTestStudentVisaDegreeLevelSEO,
} from "../../../SEO/AllEnglishTestSEO";

export const LiveData = [
  {
    h3Title: "A1 Speaking & Listening Test",
    h4Title: "CEFR A1",
    pText: "Further Leave to Remain (Family Partner / Family Parent)",
    learnMoreLink: "live/a1-english-test",
    bookTestLink:
      "https://selt.languagecert.org/search-selt-exam?exam=A104EE3F80A249B9A74B3C1862FE3CE9&country=50EB9A019EAB4678AE83D2DA8A4EC680",
    SEO: <A1EnglishTestSEO />,
  },
  {
    h3Title: "A2 Speaking & Listening Test",
    h4Title: "CEFR A2",
    pText:
      "Further Leave to Remain, visa extension after 2.5 years in the UK (Family Partner / Family Parent)",
    learnMoreLink: "live/a2-english-test",
    bookTestLink:
      "https://selt.languagecert.org/search-selt-exam?exam=6B5AB3D856F44D04A5928D33A1631409&country=50EB9A019EAB4678AE83D2DA8A4EC680",
    SEO: <A2EnglishTestSEO />,
  },
  {
    h3Title: "B1 Speaking & Listening Test",
    h4Title: "CEFR B1",
    pText: "Indefinite Leave to Remain (Settlement / Permanent Residency)",
    learnMoreLink: "live/b1-english-test",
    bookTestLink:
      "https://selt.languagecert.org/search-selt-exam?exam=B69FD9BA08FD4F3EABE4E326BFE86F12&country=50EB9A019EAB4678AE83D2DA8A4EC680",
    SEO: <B1EnglishTestSEO />,
  },
  {
    h3Title: "B1 Speaking & Listening Test",
    h4Title: "CEFR B1",
    pText: "British Citizenship (Naturalization)",
    learnMoreLink: "live/b1-english-test-naturalization",
    bookTestLink:
      "https://selt.languagecert.org/search-selt-exam?exam=B69FD9BA08FD4F3EABE4E326BFE86F12&country=50EB9A019EAB4678AE83D2DA8A4EC680",
    SEO: <B1EnglishTestNaturalizationSEO />,
  },
];

//! work data

export const WorkData = [
  {
    h3Title: "A1 Speaking & Listening Test",
    h4Title: "CEFR A1",
    pText: "Representative of an Overseas Business Visa",
    learnMoreLink: "work/a1-english-test-businessVisa",
    bookTestLink:
      "https://selt.languagecert.org/search-selt-exam?exam=A104EE3F80A249B9A74B3C1862FE3CE9&country=50EB9A019EAB4678AE83D2DA8A4EC680",
    SEO: <A1EnglishTestBusinessVisaSEO />,
  },
  {
    h3Title: "A1 Speaking & Listening Test",
    h4Title: "CEFR A1",
    pText: "Sportsperson Visa",
    learnMoreLink: "work/a1-english-test-sportspersonVisa",
    bookTestLink:
      "https://selt.languagecert.org/search-selt-exam?exam=A104EE3F80A249B9A74B3C1862FE3CE9&country=50EB9A019EAB4678AE83D2DA8A4EC680",
    SEO: <A1EnglishTestSportspersonVisaSEO />,
  },
  {
    h3Title: "B1 Speaking, Listening, Reading, Writing Test",
    h4Title: "CEFR B1",
    pText: "Scale-up Worker visa",
    learnMoreLink: "work/b1-english-test-scaleUpWorkerVisa",
    bookTestLink:
      "https://selt.languagecert.org/search-selt-exam?exam=C7ABD8CCDDBC4092B6A526DCA0760191&country=50EB9A019EAB4678AE83D2DA8A4EC680",
    SEO: <B1EnglishTestScaleUpWorkerVisaSEO />,
  },
  {
    h3Title: "B1 Speaking, Listening, Reading, Writing Test",
    h4Title: "CEFR B1",
    pText: "International Agreement visa (Temporary work)",
    learnMoreLink: "work/b1-english-test-internationalAgreementVisa",
    bookTestLink:
      "https://selt.languagecert.org/search-selt-exam?exam=C7ABD8CCDDBC4092B6A526DCA0760191&country=50EB9A019EAB4678AE83D2DA8A4EC680",
    SEO: <B1EnglishTestInternationalAgreementVisaSEO />,
  },
  {
    h3Title: "B1 Speaking, Listening, Reading, Writing Test",
    h4Title: "CEFR B1",
    pText: "Skilled Worker visa",
    learnMoreLink: "work/b1-english-test-skilledWorkerVisa",
    bookTestLink:
      "https://selt.languagecert.org/search-selt-exam?exam=C7ABD8CCDDBC4092B6A526DCA0760191&country=50EB9A019EAB4678AE83D2DA8A4EC680",
    SEO: <B1EnglishTestSkilledWorkerVisaSEO />,
  },
  {
    h3Title: "B1 Speaking, Listening, Reading, Writing Test",
    h4Title: "CEFR B1",
    pText: "High Potential individual visa",
    learnMoreLink: "work/b1-english-test-highPotentialIndividualVisa",
    bookTestLink:
      "https://selt.languagecert.org/search-selt-exam?exam=C7ABD8CCDDBC4092B6A526DCA0760191&country=50EB9A019EAB4678AE83D2DA8A4EC680",
    SEO: <B1EnglishTestHighPotentialIndividualVisaSEO />,
  },
  {
    h3Title: "B1 Speaking, Listening, Reading, Writing Test",
    h4Title: "CEFR B1",
    pText: "Health & Care Worker visa",
    learnMoreLink: "work/b1-english-test-healthCareWorkerVisa",
    bookTestLink:
      "https://selt.languagecert.org/search-selt-exam?exam=C7ABD8CCDDBC4092B6A526DCA0760191&country=50EB9A019EAB4678AE83D2DA8A4EC680",
    SEO: <B1EnglishTestHealthCareWorkerVisaSEO />,
  },
  {
    h3Title: "B2 Speaking, Listening, Reading, Writing Test",
    h4Title: "CEFR B2",
    pText: "Minister of Religion visa",
    learnMoreLink: "work/b2-english-test-ministerOfReligionVisa",
    bookTestLink:
      "https://selt.languagecert.org/search-selt-exam?exam=C7ABD8CCDDBC4092B6A526DCA0760191&country=50EB9A019EAB4678AE83D2DA8A4EC680",
    SEO: <B2EnglishTestMinisterOfReligionVisaSEO />,
  },
  {
    h3Title: "B2 Speaking, Listening, Reading, Writing Test",
    h4Title: "CEFR B2",
    pText: "Innovator Founder visa",
    learnMoreLink: "work/b2-english-test-innovatorFounderVisa",
    bookTestLink:
      "https://selt.languagecert.org/search-selt-exam?exam=C7ABD8CCDDBC4092B6A526DCA0760191&country=50EB9A019EAB4678AE83D2DA8A4EC680",
    SEO: <B2EnglishTestInnovatorFounderVisaSEO />,
  },
  {
    h3Title: "B2 Speaking, Listening, Reading, Writing Test",
    h4Title: "CEFR B2",
    pText: "Start-up visa",
    learnMoreLink: "work/b2-english-test-startUpVisa",
    bookTestLink:
      "https://selt.languagecert.org/search-selt-exam?exam=C7ABD8CCDDBC4092B6A526DCA0760191&country=50EB9A019EAB4678AE83D2DA8A4EC680",
    SEO: <B2EnglishTestStartUpVisaSEO />,
  },
];

//! study data

export const StudyData = [
  {
    h3Title: "B1 Speaking, Listening, Reading, Writing Test",
    h4Title: "CEFR B1",
    pText: "Student visa - below degree level",
    learnMoreLink: "study/b1-english-test-studentVisa-belowDegreeLevel",
    bookTestLink:
      "https://selt.languagecert.org/search-selt-exam?exam=5D0088D5BFCC4E08B58A6C98F1057D80&country=50EB9A019EAB4678AE83D2DA8A4EC680",
    SEO: <B1EnglishTestStudentVisaBelowDegreeLevelSEO />,
  },
  {
    h3Title: "B2 Speaking & Listening Test",
    h4Title: "CEFR B2",
    pText: "Student visa - degree level and above",
    learnMoreLink: "study/b2-english-test-studentVisa-degreeLevel",
    bookTestLink:
      "https://selt.languagecert.org/search-selt-exam?exam=5D0088D5BFCC4E08B58A6C98F1057D80&country=50EB9A019EAB4678AE83D2DA8A4EC680",
    SEO: <B2EnglishTestStudentVisaDegreeLevelSEO />,
  },
];
