import React from "react";
import { Helmet } from "react-helmet";

//! For the live in the UK test
export const A1EnglishTestSEO = () => {
  return (
    <Helmet>
      <title>
        A1 English Test - SELT & IELTS Preparation at Preston Academy of English
      </title>
      <meta
        name="description"
        content="Prepare for your A1 English Test with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="keywords"
        content="A1 English Test, SELT preparation, IELTS preparation, English exam courses, Preston Academy of English, A1 test, visa preparation, citizenship test, English exam discounts"
      />
      <meta
        property="og:title"
        content="A1 English Test - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        property="og:description"
        content="Prepare for your A1 English Test with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.prestonacademyofenglish.com/english-test/SELT-english-tests/live/a1-english-test"
      />
      <meta
        property="og:image"
        content="https://www.prestonacademyofenglish.com/og-image-a1-english-test.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="A1 English Test - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        name="twitter:description"
        content="Prepare for your A1 English Test with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="twitter:image"
        content="https://www.prestonacademyofenglish.com/twitter-image-a1-english-test.jpg"
      />
    </Helmet>
  );
};

export const A2EnglishTestSEO = () => {
  return (
    <Helmet>
      <title>
        A2 English Test - SELT & IELTS Preparation at Preston Academy of English
      </title>
      <meta
        name="description"
        content="Prepare for your A2 English Test with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="keywords"
        content="A2 English Test, SELT preparation, IELTS preparation, English exam courses, Preston Academy of English, A2 test, visa preparation, citizenship test, English exam discounts"
      />
      <meta
        property="og:title"
        content="A2 English Test - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        property="og:description"
        content="Prepare for your A2 English Test with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.prestonacademyofenglish.com/english-test/SELT-english-tests/live/a2-english-test"
      />
      <meta
        property="og:image"
        content="https://www.prestonacademyofenglish.com/og-image-a2-english-test.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="A2 English Test - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        name="twitter:description"
        content="Prepare for your A2 English Test with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="twitter:image"
        content="https://www.prestonacademyofenglish.com/twitter-image-a2-english-test.jpg"
      />
    </Helmet>
  );
};

export const B1EnglishTestSEO = () => {
  return (
    <Helmet>
      <title>
        B1 English Test - SELT & IELTS Preparation at Preston Academy of English
      </title>
      <meta
        name="description"
        content="Prepare for your B1 English Test with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="keywords"
        content="B1 English Test, SELT preparation, IELTS preparation, English exam courses, Preston Academy of English, B1 test, visa preparation, citizenship test, English exam discounts"
      />
      <meta
        property="og:title"
        content="B1 English Test - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        property="og:description"
        content="Prepare for your B1 English Test with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.prestonacademyofenglish.com/english-test/SELT-english-tests/live/b1-english-test"
      />
      <meta
        property="og:image"
        content="https://www.prestonacademyofenglish.com/og-image-b1-english-test.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="B1 English Test - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        name="twitter:description"
        content="Prepare for your B1 English Test with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="twitter:image"
        content="https://www.prestonacademyofenglish.com/twitter-image-b1-english-test.jpg"
      />
    </Helmet>
  );
};

export const B1EnglishTestNaturalizationSEO = () => {
  return (
    <Helmet>
      <title>
        B1 English Test for Naturalization - SELT & IELTS Preparation at Preston
        Academy of English
      </title>
      <meta
        name="description"
        content="Prepare for your B1 English Test for Naturalization with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="keywords"
        content="B1 English Test for Naturalization, SELT preparation, IELTS preparation, English exam courses, Preston Academy of English, B1 test, naturalization test, visa preparation, citizenship test, English exam discounts"
      />
      <meta
        property="og:title"
        content="B1 English Test for Naturalization - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        property="og:description"
        content="Prepare for your B1 English Test for Naturalization with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.prestonacademyofenglish.com/english-test/SELT-english-tests/live/b1-english-test-naturalization"
      />
      <meta
        property="og:image"
        content="https://www.prestonacademyofenglish.com/og-image-b1-english-test-naturalization.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="B1 English Test for Naturalization - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        name="twitter:description"
        content="Prepare for your B1 English Test for Naturalization with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="twitter:image"
        content="https://www.prestonacademyofenglish.com/twitter-image-b1-english-test-naturalization.jpg"
      />
    </Helmet>
  );
};

//! For the Work in the UK test
export const A1EnglishTestBusinessVisaSEO = () => {
  return (
    <Helmet>
      <title>
        A1 English Test for Business Visa - SELT & IELTS Preparation at Preston
        Academy of English
      </title>
      <meta
        name="description"
        content="Prepare for your A1 English Test for Business Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="keywords"
        content="A1 English Test, Business Visa English Test, SELT preparation, IELTS preparation, English exam courses, Preston Academy of English, A1 test, visa preparation, business visa test, English exam discounts"
      />
      <meta
        property="og:title"
        content="A1 English Test for Business Visa - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        property="og:description"
        content="Prepare for your A1 English Test for Business Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.prestonacademyofenglish.com/english-test/SELT-english-tests/work/a1-english-test-businessVisa"
      />
      <meta
        property="og:image"
        content="https://www.prestonacademyofenglish.com/og-image-a1-english-test-businessVisa.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="A1 English Test for Business Visa - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        name="twitter:description"
        content="Prepare for your A1 English Test for Business Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="twitter:image"
        content="https://www.prestonacademyofenglish.com/twitter-image-a1-english-test-businessVisa.jpg"
      />
    </Helmet>
  );
};

export const A1EnglishTestSportspersonVisaSEO = () => {
  return (
    <Helmet>
      <title>
        A1 English Test for Sportsperson Visa - SELT & IELTS Preparation at
        Preston Academy of English
      </title>
      <meta
        name="description"
        content="Prepare for your A1 English Test for Sportsperson Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="keywords"
        content="A1 English Test, Sportsperson Visa English Test, SELT preparation, IELTS preparation, English exam courses, Preston Academy of English, A1 test, visa preparation, sportsperson visa test, English exam discounts"
      />
      <meta
        property="og:title"
        content="A1 English Test for Sportsperson Visa - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        property="og:description"
        content="Prepare for your A1 English Test for Sportsperson Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.prestonacademyofenglish.com/english-test/SELT-english-tests/work/a1-english-test-sportspersonVisa"
      />
      <meta
        property="og:image"
        content="https://www.prestonacademyofenglish.com/og-image-a1-english-test-sportspersonVisa.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="A1 English Test for Sportsperson Visa - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        name="twitter:description"
        content="Prepare for your A1 English Test for Sportsperson Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="twitter:image"
        content="https://www.prestonacademyofenglish.com/twitter-image-a1-english-test-sportspersonVisa.jpg"
      />
    </Helmet>
  );
};

export const B1EnglishTestScaleUpWorkerVisaSEO = () => {
  return (
    <Helmet>
      <title>
        B1 English Test for Scale-up Worker Visa - SELT & IELTS Preparation at
        Preston Academy of English
      </title>
      <meta
        name="description"
        content="Prepare for your B1 English Test for Scale-up Worker Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="keywords"
        content="B1 English Test, Scale-up Worker Visa English Test, SELT preparation, IELTS preparation, English exam courses, Preston Academy of English, B1 test, visa preparation, business visa test, English exam discounts"
      />
      <meta
        property="og:title"
        content="B1 English Test for Scale-up Worker Visa - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        property="og:description"
        content="Prepare for your B1 English Test for Scale-up Worker Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.prestonacademyofenglish.com/english-test/SELT-english-tests/work/b1-english-test-scaleUpWorkerVisa"
      />
      <meta
        property="og:image"
        content="https://www.prestonacademyofenglish.com/og-image-b1-english-test-scaleUpWorkerVisa.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="B1 English Test for Scale-up Worker Visa - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        name="twitter:description"
        content="Prepare for your B1 English Test for Scale-up Worker Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="twitter:image"
        content="https://www.prestonacademyofenglish.com/twitter-image-b1-english-test-scaleUpWorkerVisa.jpg"
      />
    </Helmet>
  );
};

export const B1EnglishTestInternationalAgreementVisaSEO = () => {
  return (
    <Helmet>
      <title>
        B1 English Test for International Agreement Visa - SELT & IELTS
        Preparation at Preston Academy of English
      </title>
      <meta
        name="description"
        content="Prepare for your B1 English Test for International Agreement Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="keywords"
        content="B1 English Test, International Agreement Visa English Test, SELT preparation, IELTS preparation, English exam courses, Preston Academy of English, B1 test, visa preparation, business visa test, English exam discounts"
      />
      <meta
        property="og:title"
        content="B1 English Test for International Agreement Visa - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        property="og:description"
        content="Prepare for your B1 English Test for International Agreement Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.prestonacademyofenglish.com/english-test/SELT-english-tests/work/b1-english-test-internationalAgreementVisa"
      />
      <meta
        property="og:image"
        content="https://www.prestonacademyofenglish.com/og-image-b1-english-test-internationalAgreementVisa.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="B1 English Test for International Agreement Visa - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        name="twitter:description"
        content="Prepare for your B1 English Test for International Agreement Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="twitter:image"
        content="https://www.prestonacademyofenglish.com/twitter-image-b1-english-test-internationalAgreementVisa.jpg"
      />
    </Helmet>
  );
};

export const B1EnglishTestSkilledWorkerVisaSEO = () => {
  return (
    <Helmet>
      <title>
        B1 English Test for Skilled Worker Visa - SELT & IELTS Preparation at
        Preston Academy of English
      </title>
      <meta
        name="description"
        content="Prepare for your B1 English Test for Skilled Worker Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="keywords"
        content="B1 English Test, Skilled Worker Visa English Test, SELT preparation, IELTS preparation, English exam courses, Preston Academy of English, B1 test, visa preparation, skilled worker visa test, English exam discounts"
      />
      <meta
        property="og:title"
        content="B1 English Test for Skilled Worker Visa - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        property="og:description"
        content="Prepare for your B1 English Test for Skilled Worker Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.prestonacademyofenglish.com/english-test/SELT-english-tests/work/b1-english-test-skilledWorkerVisa"
      />
      <meta
        property="og:image"
        content="https://www.prestonacademyofenglish.com/og-image-b1-english-test-skilledWorkerVisa.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="B1 English Test for Skilled Worker Visa - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        name="twitter:description"
        content="Prepare for your B1 English Test for Skilled Worker Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="twitter:image"
        content="https://www.prestonacademyofenglish.com/twitter-image-b1-english-test-skilledWorkerVisa.jpg"
      />
    </Helmet>
  );
};

export const B1EnglishTestHighPotentialIndividualVisaSEO = () => {
  return (
    <Helmet>
      <title>
        B1 English Test for High Potential Individual Visa - SELT & IELTS
        Preparation at Preston Academy of English
      </title>
      <meta
        name="description"
        content="Prepare for your B1 English Test for High Potential Individual Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="keywords"
        content="B1 English Test, High Potential Individual Visa English Test, SELT preparation, IELTS preparation, English exam courses, Preston Academy of English, B1 test, visa preparation, high potential individual visa test, English exam discounts"
      />
      <meta
        property="og:title"
        content="B1 English Test for High Potential Individual Visa - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        property="og:description"
        content="Prepare for your B1 English Test for High Potential Individual Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.prestonacademyofenglish.com/english-test/SELT-english-tests/work/b1-english-test-highPotentialIndividualVisa"
      />
      <meta
        property="og:image"
        content="https://www.prestonacademyofenglish.com/og-image-b1-english-test-highPotentialIndividualVisa.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="B1 English Test for High Potential Individual Visa - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        name="twitter:description"
        content="Prepare for your B1 English Test for High Potential Individual Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="twitter:image"
        content="https://www.prestonacademyofenglish.com/twitter-image-b1-english-test-highPotentialIndividualVisa.jpg"
      />
    </Helmet>
  );
};

export const B1EnglishTestHealthCareWorkerVisaSEO = () => {
  return (
    <Helmet>
      <title>
        B1 English Test for Health Care Worker Visa - SELT & IELTS Preparation
        at Preston Academy of English
      </title>
      <meta
        name="description"
        content="Prepare for your B1 English Test for Health Care Worker Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="keywords"
        content="B1 English Test, Health Care Worker Visa English Test, SELT preparation, IELTS preparation, English exam courses, Preston Academy of English, B1 test, visa preparation, health care worker visa test, English exam discounts"
      />
      <meta
        property="og:title"
        content="B1 English Test for Health Care Worker Visa - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        property="og:description"
        content="Prepare for your B1 English Test for Health Care Worker Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.prestonacademyofenglish.com/english-test/SELT-english-tests/work/b1-english-test-healthCareWorkerVisa"
      />
      <meta
        property="og:image"
        content="https://www.prestonacademyofenglish.com/og-image-b1-english-test-healthCareWorkerVisa.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="B1 English Test for Health Care Worker Visa - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        name="twitter:description"
        content="Prepare for your B1 English Test for Health Care Worker Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="twitter:image"
        content="https://www.prestonacademyofenglish.com/twitter-image-b1-english-test-healthCareWorkerVisa.jpg"
      />
    </Helmet>
  );
};

export const B2EnglishTestMinisterOfReligionVisaSEO = () => {
  return (
    <Helmet>
      <title>
        B2 English Test for Minister of Religion Visa - SELT & IELTS Preparation
        at Preston Academy of English
      </title>
      <meta
        name="description"
        content="Prepare for your B2 English Test for Minister of Religion Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="keywords"
        content="B2 English Test, Minister of Religion Visa English Test, SELT preparation, IELTS preparation, English exam courses, Preston Academy of English, B2 test, visa preparation, minister of religion visa test, English exam discounts"
      />
      <meta
        property="og:title"
        content="B2 English Test for Minister of Religion Visa - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        property="og:description"
        content="Prepare for your B2 English Test for Minister of Religion Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.prestonacademyofenglish.com/english-test/SELT-english-tests/work/b2-english-test-ministerOfReligionVisa"
      />
      <meta
        property="og:image"
        content="https://www.prestonacademyofenglish.com/og-image-b2-english-test-ministerOfReligionVisa.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="B2 English Test for Minister of Religion Visa - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        name="twitter:description"
        content="Prepare for your B2 English Test for Minister of Religion Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="twitter:image"
        content="https://www.prestonacademyofenglish.com/twitter-image-b2-english-test-ministerOfReligionVisa.jpg"
      />
    </Helmet>
  );
};

export const B2EnglishTestInnovatorFounderVisaSEO = () => {
  return (
    <Helmet>
      <title>
        B2 English Test for Innovator Founder Visa - SELT & IELTS Preparation at
        Preston Academy of English
      </title>
      <meta
        name="description"
        content="Prepare for your B2 English Test for Innovator Founder Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="keywords"
        content="B2 English Test, Innovator Founder Visa English Test, SELT preparation, IELTS preparation, English exam courses, Preston Academy of English, B2 test, visa preparation, innovator founder visa test, English exam discounts"
      />
      <meta
        property="og:title"
        content="B2 English Test for Innovator Founder Visa - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        property="og:description"
        content="Prepare for your B2 English Test for Innovator Founder Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.prestonacademyofenglish.com/english-test/SELT-english-tests/work/b2-english-test-innovatorFounderVisa"
      />
      <meta
        property="og:image"
        content="https://www.prestonacademyofenglish.com/og-image-b2-english-test-innovatorFounderVisa.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="B2 English Test for Innovator Founder Visa - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        name="twitter:description"
        content="Prepare for your B2 English Test for Innovator Founder Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="twitter:image"
        content="https://www.prestonacademyofenglish.com/twitter-image-b2-english-test-innovatorFounderVisa.jpg"
      />
    </Helmet>
  );
};

export const B2EnglishTestStartUpVisaSEO = () => {
  return (
    <Helmet>
      <title>
        B2 English Test for Start-up Visa - SELT & IELTS Preparation at Preston
        Academy of English
      </title>
      <meta
        name="description"
        content="Prepare for your B2 English Test for Start-up Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="keywords"
        content="B2 English Test, Start-up Visa English Test, SELT preparation, IELTS preparation, English exam courses, Preston Academy of English, B2 test, visa preparation, start-up visa test, English exam discounts"
      />
      <meta
        property="og:title"
        content="B2 English Test for Start-up Visa - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        property="og:description"
        content="Prepare for your B2 English Test for Start-up Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.prestonacademyofenglish.com/english-test/SELT-english-tests/work/b2-english-test-startUpVisa"
      />
      <meta
        property="og:image"
        content="https://www.prestonacademyofenglish.com/og-image-b2-english-test-startUpVisa.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="B2 English Test for Start-up Visa - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        name="twitter:description"
        content="Prepare for your B2 English Test for Start-up Visa with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="twitter:image"
        content="https://www.prestonacademyofenglish.com/twitter-image-b2-english-test-startUpVisa.jpg"
      />
    </Helmet>
  );
};

//! For the study in the UK test
export const B1EnglishTestStudentVisaBelowDegreeLevelSEO = () => {
  return (
    <Helmet>
      <title>
        B1 English Test for Student Visa Below Degree Level - SELT & IELTS
        Preparation at Preston Academy of English
      </title>
      <meta
        name="description"
        content="Prepare for your B1 English Test for Student Visa Below Degree Level with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="keywords"
        content="B1 English Test, Student Visa Below Degree Level, SELT preparation, IELTS preparation, English exam courses, Preston Academy of English, B1 test, visa preparation, student visa test, English exam discounts"
      />
      <meta
        property="og:title"
        content="B1 English Test for Student Visa Below Degree Level - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        property="og:description"
        content="Prepare for your B1 English Test for Student Visa Below Degree Level with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.prestonacademyofenglish.com/english-test/SELT-english-tests/study/b1-english-test-studentVisa-belowDegreeLevel"
      />
      <meta
        property="og:image"
        content="https://www.prestonacademyofenglish.com/og-image-b1-english-test-studentVisa-belowDegreeLevel.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="B1 English Test for Student Visa Below Degree Level - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        name="twitter:description"
        content="Prepare for your B1 English Test for Student Visa Below Degree Level with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="twitter:image"
        content="https://www.prestonacademyofenglish.com/twitter-image-b1-english-test-studentVisa-belowDegreeLevel.jpg"
      />
    </Helmet>
  );
};

export const B2EnglishTestStudentVisaDegreeLevelSEO = () => {
  return (
    <Helmet>
      <title>
        B2 English Test for Student Visa Degree Level - SELT & IELTS Preparation
        at Preston Academy of English
      </title>
      <meta
        name="description"
        content="Prepare for your B2 English Test for Student Visa Degree Level with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="keywords"
        content="B2 English Test, Student Visa Degree Level, SELT preparation, IELTS preparation, English exam courses, Preston Academy of English, B2 test, visa preparation, student visa test, English exam discounts"
      />
      <meta
        property="og:title"
        content="B2 English Test for Student Visa Degree Level - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        property="og:description"
        content="Prepare for your B2 English Test for Student Visa Degree Level with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.prestonacademyofenglish.com/english-test/SELT-english-tests/study/b2-english-test-studentVisa-degreeLevel"
      />
      <meta
        property="og:image"
        content="https://www.prestonacademyofenglish.com/og-image-b2-english-test-studentVisa-degreeLevel.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="B2 English Test for Student Visa Degree Level - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        name="twitter:description"
        content="Prepare for your B2 English Test for Student Visa Degree Level with Preston Academy of English. Our expert guidance and comprehensive courses for SELT and IELTS exams will help you succeed. Take advantage of our special promotions and excel in your English tests."
      />
      <meta
        name="twitter:image"
        content="https://www.prestonacademyofenglish.com/twitter-image-b2-english-test-studentVisa-degreeLevel.jpg"
      />
    </Helmet>
  );
};
