import React from "react";
import { NavLink } from "react-router-dom";
import { MdContactPhone } from "react-icons/md";
import { FaDownload } from "react-icons/fa";
import "./dropDownPriceList.css";

const DropDownPriceList = () => {
  return (
    <div className="dropDownPriceList-cont">
      <ul className="dropDownPriceList-contItem">
        <div>
          <NavLink to="/downloadable-files">
            <li>
              <FaDownload />
              Helpful Files
            </li>
          </NavLink>
        </div>
      </ul>
    </div>
  );
};

export default DropDownPriceList;
