import { useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import "./couponModal.css";

const CouponModal = ({ closeModal }) => {
  //! This is to copy the coupon code func
  const [coupon] = useState("ED0B80");
  const copyToClipboard = () => {
    navigator.clipboard.writeText(coupon);
    alert("Coupon code copied to clipboard!");
  };
  return (
    <div className="coupon-modalMainCont">
      <div className="coupon-modal">
        <div className="closeModalButton" onClick={() => closeModal(false)}>
          <IoMdCloseCircle />
        </div>
        <div className="coupon-modalDetail">
          <h1>
            <span className="red-head">10% off</span> all tests. Copy code below
          </h1>
          <div className="coupon">
            <span>Discount Code - {coupon}</span>
            <button onClick={copyToClipboard}>Copy Code</button>
          </div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://selt.languagecert.org/"
          >
            Continue to Booking Test{" >"}
          </a>
        </div>
      </div>
    </div>
  );
};

export default CouponModal;
