import React from "react";
import "./more-info.css";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import MoreInfoCoupon from "../couponModal/couponForMore-infoPage/MoreInfoCouponModal";

const MoreInfo = () => {
  //! This is to copy the coupon code func
  const [openCouponModal, setOpenCouponModal] = useState(false);

  return (
    <>
      <Header />
      <div className="more-infoMainCont">
        <div className="each-testCont" style={{ border: "2px solid #019cdf" }}>
          <h3> Academic</h3>
          <div className="priceCont">
            <h2 onClick={() => setOpenCouponModal(true)}>
              click for <br /> 10% off <br />{" "}
              <span className="blue-head">Code</span>
            </h2>
          </div>
          <p>For University and College Admission</p>
          <div className="test-btnCont">
            <NavLink className="red-testBtn" to="academic">
              Learn More
            </NavLink>
          </div>
        </div>
        {/* //* For languageCert General */}
        <div className="each-testCont" style={{ border: "2px solid #019cdf" }}>
          <h3> General</h3>
          <div className="priceCont">
            <h2 onClick={() => setOpenCouponModal(true)}>
              click for <br /> 10% off <br />{" "}
              <span className="blue-head">Code</span>
            </h2>
          </div>
          <p>For Work and General Purposes</p>
          <div className="test-btnCont">
            <NavLink className="red-testBtn" to="general">
              Learn More
            </NavLink>
          </div>
        </div>
        {/* //* For languageCert ESOL */}
        <div className="each-testCont" style={{ border: "2px solid #019cdf" }}>
          <h3> ESOL</h3>
          <div className="priceCont">
            <h2 onClick={() => setOpenCouponModal(true)}>
              click for <br /> 10% off <br />{" "}
              <span className="blue-head">Code</span>
            </h2>
          </div>
          <p>For Study and General Purposes</p>
          <div className="test-btnCont">
            <a
              href="https://www.languagecert.org/en/language-exams/english/languagecert-international-esol"
              target="_blank"
              rel="noreferrer"
              className="red-testBtn"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
      {openCouponModal && <MoreInfoCoupon closeModal={setOpenCouponModal} />}

      <Footer />
    </>
  );
};

export default MoreInfo;
