import React from "react";
import "./testCentre.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import ScrollDown from "./scrolldownbtn/ScrollDown";
import BottomTestScrollPage from "./bottomtestScrollPage/BottomTestScrollPage";
import logo from "../../assets/img/test-logo.png";
import logo2 from "../../assets/img/test-centre-logo2.png";
import logo3 from "../../assets/img/test-logo3.png";
import logo4 from "../../assets/img/test-logo4.png";
import logo5 from "../../assets/img/test-logo5.png";
import EnglishTestSEO from "../../components/SEO/EnglishTestSEO";

const TestCentre = () => {
  const logoData = [
    {
      logoImg: logo,
    },
    {
      logoImg: logo2,
    },
    {
      logoImg: logo3,
    },
    {
      logoImg: logo4,
    },
    {
      logoImg: logo5,
    },
  ];
  return (
    <>
      <EnglishTestSEO />
      <Header />
      <div className="test-centreCont">
        <div className="test-centreHeroCont">
          <div className="test-centreHeroText">
            <h1>
              Welcome to <span className="red-head">LanguageCert Exams</span> at{" "}
              <span className="blue-head">
                PAE (Preston Academy of English)
              </span>
            </h1>
            <p>
              PAE is a SELT Examinations Partner as well as LanguageCert Online
              Examinations Partner.
            </p>
            <ScrollDown />
            {/* //* Logo partner display container */}
            <div className="carousel ">
              <div className="carousel-inner">
                {logoData.map((logo, index) => (
                  <div className="carousel-item">
                    <img src={logo.logoImg} alt="" />
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* //* This is the div container of a side image */}
          <div className="test-centreHeroImg"></div>
        </div>
        {/* //* For the bottom container for selt exams page */}
        <BottomTestScrollPage />
      </div>
      <Footer />
    </>
  );
};

export default TestCentre;
