import React, { useState } from "react";
import "./bannerBody.css";
import { FaRegCopy } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const BannerBody = () => {
  //! This is to copy the coupon code func
  const [coupon] = useState("ED0B80");
  const copyToClipboard = () => {
    navigator.clipboard.writeText(coupon);
    alert("Coupon code copied to clipboard!");
  };
  return (
    <>
      <div className="bannerBody-mainCont">
        <div className="bannerBody-cont">
          <h1>
            GET UP TO <br /> <span>10% OFF</span> <br /> ON ALL TESTS
          </h1>
          <p>Copy the Code below and use it when booking all tests</p>

          <div className="bannerBody-btnCont" onClick={copyToClipboard}>
            <p>ED0B80</p>
            <button>
              Copy <FaRegCopy />
            </button>
          </div>
          <p>
            {" "}
            Press the link button below to explore the tests available or
            Message us for more information.
          </p>
          <div className="bannerBody-linkBtnCont">
            <NavLink
              to="/english-test/SELT-english-tests"
              className="bannerBody-link"
            >
              Explore Tests
            </NavLink>
            <a
              href="https://wa.me/441772821039"
              target="_blank"
              rel="noreferrer"
            >
              Messsage Us
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerBody;
