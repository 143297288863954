import React, { useState } from "react";

const GeneralCoupon = () => {
  //! This is to copy the coupon code func
  const [coupon] = useState("ED0B80");
  const copyToClipboard = () => {
    navigator.clipboard.writeText(coupon);
    alert("Coupon code copied to clipboard!");
  };
  return (
    <div className="coupon" style={{ marginTop: "1rem" }}>
      <span>Discount Code - {coupon}</span>
      <button onClick={copyToClipboard}>Copy Code</button>
    </div>
  );
};

export default GeneralCoupon;
