import React, { useState } from "react";
import "./A1.css";
import { FaGraduationCap } from "react-icons/fa6";
import { FaUserGraduate } from "react-icons/fa6";
import { TbDeviceDesktopUp } from "react-icons/tb";
import { IoCheckmark } from "react-icons/io5";
import { GiPassport } from "react-icons/gi";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import { FaGlobeAmericas } from "react-icons/fa";
import { GiNetworkBars } from "react-icons/gi";
import { IoMdTimer } from "react-icons/io";
import { VscChecklist } from "react-icons/vsc";
import { NavLink } from "react-router-dom";
import GeneralCoupon from "./GeneralCoupon";
import CouponModal from "../../couponModal/CouponModal";

const GeneralLearnMoreExamPage = ({
  h1Text,
  bookSlot,
  dataMap,
  pText,
  header,
  Seo,
}) => {
  const [openCouponModal, setOpenCouponModal] = useState(false);

  return (
    <>
      {Seo}
      <Header />
      <div className="a1generalCont">
        <div className="aLearn-moreCont">
          <div className="aLearn-leftHeader">
            <h1>{h1Text}</h1>

            <GeneralCoupon />
          </div>
          <div className="aLearn-rightHeader">
            <h3>Suitable for</h3>
            <div className="iconCont">
              <div className="iconCont-item">
                <FaUserGraduate className="icon-general" />
                <p>{header}</p>
              </div>
              <div className="iconCont-item">
                <GiPassport
                  className="icon-general"
                  style={{ fontSize: "3rem" }}
                />
                <p>{pText}</p>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="aLearn-detailCont">
          <div className="aLearn-detailItem">
            <h3>What's included</h3>
            <p>Speaking & Listening</p>
          </div>
          <hr />
          <div className="aLearn-detailItem">
            <h3>Exam Type</h3>
            <p>Test Centre</p>
          </div>
          <hr />
          <div className="aLearn-detailItem">
            <h3>Results</h3>
            <div className="resultCont">
              <TbDeviceDesktopUp className="resultCont-icon" />
              <p>
                Results comes out in <b>3 working days</b>{" "}
              </p>
            </div>
          </div>
          <hr />
          <div className="aLearn-detailItem">
            <h3>Total</h3>
            <div className="aLearn-priceCont">
              {/* //Todo: coupon code will be added here instead of price */}
              {/* <p>£150.00</p> */}

              <div>
                <IoCheckmark className="aLearn-icon" />
                Written & Spoken exam
              </div>
              <div>
                <IoCheckmark className="aLearn-icon" />
                Test Centre
              </div>
              <div>
                <IoCheckmark className="aLearn-icon" />
                Take 2
              </div>
            </div>
            <a href={bookSlot} target="_blank" rel="noreferrer">
              Book Slot
            </a>
          </div>
        </div>
        {/*  */}
        <div className="aLearn-expectCont">
          <div className="aLearn-expectItem">
            <div className="each-expectItemCont">
              <FaGraduationCap className="expectIcon" />

              <h5>Approved by the</h5>
              <p>UK Home Office</p>
            </div>
            <div className="each-expectItemCont">
              <FaGlobeAmericas className="expectIcon" />

              <h5>Computer based test in</h5>
              <p>110+ countries</p>
            </div>
            <div className="each-expectItemCont">
              <GiNetworkBars className="expectIcon" />

              <h5>Duration: </h5>
              <p>8-9 minutes</p>
            </div>
            <div className="each-expectItemCont">
              <IoMdTimer className="expectIcon" />

              <h5>Official results in </h5>
              <p>3 working days</p>
            </div>
            <div className="each-expectItemCont">
              <VscChecklist className="expectIcon" />

              <h5>Take 2 </h5>
              <p>Resit option</p>
            </div>
          </div>
        </div>
        {/* //* other exams you may be interested in */}
        <div className="otherCourses-learnMoreCont">
          <h2>Other Exams You Maybe Interested In:</h2>
          <div>
            <div className="ukvi-testCont">
              {dataMap
                .filter((data) => data.pText !== pText)
                .map((data) => (
                  <div className="each-testCont" key={data.h3Title}>
                    <h3>{data.h3Title}</h3>
                    <div className="priceCont">
                      <h4>{data.h4Title}</h4>

                      <h4
                        className="generalLearnMore-cont"
                        onClick={() => setOpenCouponModal(true)}
                      >
                        click for <br /> 10% off <br />{" "}
                        <span className="blue-head">Code</span>
                      </h4>
                      {/* <p>£150.00</p> */}
                    </div>
                    <p>{data.pText}</p>
                    <div className="test-btnCont">
                      <NavLink
                        className="red-testBtn"
                        to={
                          "/english-test/SELT-english-tests/" +
                          data.learnMoreLink
                        }
                      >
                        Learn More
                      </NavLink>
                      <a
                        href={data.bookTestLink}
                        target="_blank"
                        rel="noreferrer"
                        className="blue-testBtn"
                      >
                        Book Test
                      </a>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {openCouponModal && <CouponModal closeModal={setOpenCouponModal} />}
      </div>
      <Footer />
    </>
  );
};

export default GeneralLearnMoreExamPage;
