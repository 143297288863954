import React from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { NavLink } from "react-router-dom";

const PriceModal = ({ closeModal }) => {
  return (
    <div className="modalBg">
      <div className="modalCont">
        <div onClick={() => closeModal(false)}>
          <IoMdCloseCircle />
        </div>
        <ul>
          <NavLink to="/about-preston-academy-of-english">
            <li>About Us</li>
          </NavLink>
          <NavLink to="/downloadable-files">
            <li>Helpful Files</li>
          </NavLink>
        </ul>
      </div>
    </div>
  );
};

export default PriceModal;
