import React, { useState } from "react";
import MoreInfoCoupon from "../../couponModal/couponForMore-infoPage/MoreInfoCouponModal";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import { FaGlobeAmericas } from "react-icons/fa";
import { GiNetworkBars } from "react-icons/gi";
import { IoMdTimer } from "react-icons/io";
import { VscChecklist } from "react-icons/vsc";
import { FaGraduationCap } from "react-icons/fa6";
import { GiPassport } from "react-icons/gi";
import GeneralCoupon from "../../resuseable_ui/ukvi_examsPage/GeneralCoupon";
import { useNavigate } from "react-router-dom";
import "./generalTest.css";

const General = () => {
  //! For the gp back router function
  let navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  const [openCouponModal, setOpenCouponModal] = useState(false);
  return (
    <>
      <Header />
      <div className="a1generalCont">
        <div className="aLearn-moreCont">
          <div className="aLearn-leftHeader">
            <h1>LanguageCert General</h1>

            <GeneralCoupon />
          </div>
          <div className="aLearn-rightHeader">
            <h3>Suitable for</h3>
            <div className="iconCont">
              <div className="iconCont-item">
                <GiPassport
                  className="icon-general"
                  style={{ fontSize: "3rem" }}
                />
                <p>
                  Individuals seeking to work in an English speaking country
                </p>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="generalTestInfo-detailCont">
          <div className="generalTestInfo-detailItem">
            <h3>What's included</h3>
          </div>
          <hr />
          <div className="generalTestInfo-detailItem">
            <h3>Exam Type</h3>
            <p>Test Centre</p>
          </div>

          <hr />
          <div className="generalTestInfo-detailItem">
            <a
              href="https://www.languagecert.org/en/language-exams/english/languagecert-general/general"
              target="_blank"
              rel="noreferrer"
            >
              Book a test
            </a>
          </div>
        </div>
        {/*  */}
        <div className="aLearn-expectCont">
          <div className="aLearn-expectItem">
            <div className="each-expectItemCont">
              <FaGraduationCap className="expectIcon" />

              <h5>Language focus</h5>
              <p>General English</p>
            </div>
            <div className="each-expectItemCont">
              <FaGlobeAmericas className="expectIcon" />

              <h5>Test availability</h5>
              <p>Worldwide</p>
            </div>
            <div className="each-expectItemCont">
              <GiNetworkBars className="expectIcon" />

              <h5>Levels </h5>
              <p>A2 to C1</p>
            </div>
            <div className="each-expectItemCont">
              <IoMdTimer className="expectIcon" />

              <h5>Duration </h5>
              <p>2 hours & 22 mins</p>
            </div>
            <div className="each-expectItemCont">
              <VscChecklist className="expectIcon" />

              <h5>Official results in</h5>
              <p>5 workings days</p>
            </div>
          </div>
        </div>

        <div className="academic-btnCont">
          <a
            href="https://www.languagecert.org/en/language-exams/english/languagecert-general/general"
            target="_blank"
            rel="noreferrer"
          >
            Book a test
          </a>
          <button style={{ background: "var(--red-color)" }} onClick={goBack}>
            Go Back
          </button>
        </div>

        {openCouponModal && <MoreInfoCoupon closeModal={setOpenCouponModal} />}
      </div>
      <Footer />
    </>
  );
};

export default General;
