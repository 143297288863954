import { NavLink } from "react-router-dom";
import "./allCategoryExams.css";
import {
  LiveData,
  StudyData,
  WorkData,
} from "./englishExamPageData/EnglishExamPageData";

const AllCategoryExamsPage = ({ setOpenCouponModal }) => {
  return (
    <>
      <div>
        <div className="english-examsCont">
          <h1>
            Live in the UK <span className="blue-head">SELT test :</span>{" "}
          </h1>
          <div className="ukvi-testCont">
            {/* //* Live the uk test */}
            {LiveData.map((data) => (
              <div
                className="each-testCont"
                style={{ border: "2px solid #ea1d24" }}
                key={data.h3Title}
              >
                <h3>{data.h3Title}</h3>
                <div className="priceCont">
                  <h4>{data.h4Title}</h4>

                  <h2 onClick={() => setOpenCouponModal(true)}>
                    click for <br /> 10% off <br />{" "}
                    <span className="blue-head">Code</span>
                  </h2>
                  {/* <p>£150.00</p> */}
                </div>
                <p>{data.pText}</p>
                <div className="test-btnCont">
                  <NavLink className="red-testBtn" to={data.learnMoreLink}>
                    Learn More
                  </NavLink>
                  <a
                    href={data.bookTestLink}
                    target="_blank"
                    rel="noreferrer"
                    className="blue-testBtn"
                  >
                    Book Test
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* //* Work in the uk test */}
        <div className="english-examsCont">
          <h1>
            Work in the UK <span className="red-head">SELT test :</span>
          </h1>
          <div className="ukvi-testCont">
            {/* //* work the uk test */}
            {WorkData.map((data) => (
              <div
                className="each-testCont"
                style={{ border: "2px solid #019cdf" }}
                key={data.h3Title}
              >
                <h3>{data.h3Title}</h3>
                <div className="priceCont">
                  <h4>{data.h4Title}</h4>

                  <h2 onClick={() => setOpenCouponModal(true)}>
                    click for <br /> 10% off <br />{" "}
                    <span className="blue-head">Code</span>
                  </h2>
                  {/* <p>£150.00</p> */}
                </div>
                <p>{data.pText}</p>
                <div className="test-btnCont">
                  <NavLink className="red-testBtn" to={data.learnMoreLink}>
                    Learn More
                  </NavLink>
                  <a
                    href={data.bookTestLink}
                    target="_blank"
                    rel="noreferrer"
                    className="blue-testBtn"
                  >
                    Book Test
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* //* Study in the uk test */}
        <div className="english-examsCont">
          <h1>
            Study in the UK <span className="blue-head"> SELT test :</span>
          </h1>
          <div className="ukvi-testCont">
            {/* //* study  the uk test */}
            {StudyData.map((data) => (
              <div
                className="each-testCont"
                style={{ border: "2px solid #ea1d24" }}
                key={data.h3Title}
              >
                <h3>{data.h3Title}</h3>
                <div className="priceCont">
                  <h4>{data.h4Title}</h4>

                  <h2 onClick={() => setOpenCouponModal(true)}>
                    click for <br /> 10% off <br />{" "}
                    <span className="blue-head">Code</span>
                  </h2>
                  {/* <p>£150.00</p> */}
                </div>
                <p>{data.pText}</p>
                <div className="test-btnCont">
                  <NavLink className="red-testBtn" to={data.learnMoreLink}>
                    Learn More
                  </NavLink>
                  <a
                    href={data.bookTestLink}
                    target="_blank"
                    rel="noreferrer"
                    className="blue-testBtn"
                  >
                    Book Test
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllCategoryExamsPage;
