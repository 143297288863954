import React from "react";
import "./bottomtestscroll.css";
import SELTImg from "../../../assets/img/selt-exams.svg";
import moreinfoImg from "../../../assets/img/more-info.svg";
import { NavLink } from "react-router-dom";

const BottomTestScrollPage = () => {
  return (
    <>
      <div className="bottomTest-MainCont" id="scrollDownPage">
        <h1 className="bottomTest-header">
          More Information On LanguageCert Exams
        </h1>
        <div className="bottomTest-cont">
          <div className="bottomTest-contDetailsCont">
            <img src={SELTImg} alt="SELT-image" />
            <h1>LanguageCert SELT for UKVI</h1>
            <p>
              Home Office approved Secure English Language Tests (SELT) for
              migration to the UK covering all visa types (Live, Work, Study)
            </p>
            <NavLink className="para-btn" to="SELT-english-tests">
              Click here for more Info
            </NavLink>
          </div>
          <div className="bottomTest-contDetailsCont">
            <img src={moreinfoImg} alt="more-info" />
            <h1>More information on LanguageCert Exams</h1>
            <p>Please find more information that could get your interest...</p>
            <NavLink to="more-info" className="para-btn">
              Click here for more Info
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default BottomTestScrollPage;
