import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainHomePage from "./pages/home/MainHomePage";
import Course from "./pages/Courses/Course";
import GeneralEnglish from "./subpages/courses-subpages/general-english/GeneralEnglish";
import EnglishIELTS from "./subpages/courses-subpages/english-ielts/EnglishIELTS";
import EnglishPrepCours from "./subpages/courses-subpages/english-prepCours/EnglishPrepCours";
import OneofOne from "./subpages/courses-subpages/oneofone/OneofOne";
import UKVISelt from "./subpages/courses-subpages/ukvi-selt/UKVISelt";
import Contact from "./pages/Contact/Contact";
import Preston from "./pages/About Preston/Preston";
import About from "./pages/About/About";
import UKVI from "./pages/UKVI Exams/UKVI";
import StudentAccom from "./subpages/Ukvi-subpages/student-accom/StudentAccom";
import ApplyStud from "./subpages/Ukvi-subpages/apply-student/ApplyStud";
import ScrollToTop from "./components/scrolltoTop/ScrollToTop";
// import TawkTo from "./components/chatwidget/TawkTo";
import PriceList from "./subpages/Ukvi-subpages/price-list/PriceList";
import UKVIForm from "./components/resuseable_ui/ukviformsapply/ukvi-form/UKVIForm";
import Whatsapp from "./components/whatsappComp/Whatsapp";
import Blog from "./pages/blog/Blog";
import SingleBlog from "./pages/blog/singleBlog/SingleBlog";
import GeneralLearnMoreExamPage from "./components/resuseable_ui/ukvi_examsPage/GeneralLearnMoreExamPage";
import {
  LiveData,
  StudyData,
  WorkData,
} from "./components/resuseable_ui/ukvi_examsPage/englishExamPageData/EnglishExamPageData";
import TestCentre from "./pages/test-centre/TestCentre";
import MoreInfo from "./components/more-info-page/More-Info";
import Academic from "./components/more-info-page/Academnic/Academic";
import General from "./components/more-info-page/General/General";
import DownloadablePage from "./pages/downloadablePage/DownloadablePage";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Whatsapp />
        <Routes>
          <Route path="/" element={<MainHomePage />} />
          <Route path="/courses" element={<Course />} />
          <Route path="/general-english" element={<GeneralEnglish />} />
          <Route path="/english-ielts" element={<EnglishIELTS />} />
          <Route path="/speaking-skills" element={<EnglishPrepCours />} />
          <Route path="/onetoone" element={<OneofOne />} />
          <Route path="/ukvi-test" element={<UKVISelt />} />
          <Route path="/about-preston-academy-of-english" element={<About />} />
          <Route path="/downloadable-files" element={<DownloadablePage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/english-test">
            <Route index element={<TestCentre />} />
            <Route path="more-info">
              <Route index element={<MoreInfo />} />
              <Route path="academic" element={<Academic />} />
              <Route path="general" element={<General />} />
            </Route>
            <Route path="SELT-english-tests">
              <Route index element={<UKVI />} />
              <Route path="live">
                <Route
                  index
                  path="a1-english-test"
                  element={
                    <GeneralLearnMoreExamPage
                      header="Live in the UK visa"
                      h1Text={LiveData[0].h3Title}
                      bookSlot={LiveData[0].bookTestLink}
                      pText={LiveData[0].pText}
                      dataMap={LiveData}
                      Seo={LiveData[0].SEO}
                    />
                  }
                />
                <Route
                  path="a2-english-test"
                  element={
                    <GeneralLearnMoreExamPage
                      header="Live in the UK visa"
                      h1Text={LiveData[1].h3Title}
                      bookSlot={LiveData[1].bookTestLink}
                      pText={LiveData[1].pText}
                      dataMap={LiveData}
                      Seo={LiveData[1].SEO}
                    />
                  }
                />
                <Route
                  path="b1-english-test"
                  element={
                    <GeneralLearnMoreExamPage
                      header="Live in the UK visa"
                      h1Text={LiveData[2].h3Title}
                      bookSlot={LiveData[2].bookTestLink}
                      pText={LiveData[2].pText}
                      dataMap={LiveData}
                      Seo={LiveData[2].SEO}
                    />
                  }
                />
                <Route
                  path="b1-english-test-naturalization"
                  element={
                    <GeneralLearnMoreExamPage
                      header="Live in the UK visa"
                      h1Text={LiveData[3].h3Title}
                      bookSlot={LiveData[3].bookTestLink}
                      pText={LiveData[3].pText}
                      dataMap={LiveData}
                      Seo={LiveData[3].SEO}
                    />
                  }
                />
              </Route>
              {/* //* Work in the UK route */}
              <Route path="work">
                <Route
                  index
                  path="a1-english-test-businessVisa"
                  element={
                    <GeneralLearnMoreExamPage
                      header="Work in the UK visa"
                      h1Text={WorkData[0].h3Title}
                      bookSlot={WorkData[0].bookTestLink}
                      pText={WorkData[0].pText}
                      dataMap={WorkData}
                      Seo={WorkData[0].SEO}
                    />
                  }
                />
                <Route
                  path="a1-english-test-sportspersonVisa"
                  element={
                    <GeneralLearnMoreExamPage
                      header="Work in the UK visa"
                      h1Text={WorkData[1].h3Title}
                      bookSlot={WorkData[1].bookTestLink}
                      pText={WorkData[1].pText}
                      dataMap={WorkData}
                      Seo={WorkData[1].SEO}
                    />
                  }
                />
                <Route
                  path="b1-english-test-scaleUpWorkerVisa"
                  element={
                    <GeneralLearnMoreExamPage
                      header="Work in the UK visa"
                      h1Text={WorkData[2].h3Title}
                      bookSlot={WorkData[2].bookTestLink}
                      pText={WorkData[2].pText}
                      dataMap={WorkData}
                      Seo={WorkData[2].SEO}
                    />
                  }
                />
                <Route
                  path="b1-english-test-internationalAgreementVisa"
                  element={
                    <GeneralLearnMoreExamPage
                      header="Work in the UK visa"
                      h1Text={WorkData[3].h3Title}
                      bookSlot={WorkData[3].bookTestLink}
                      pText={WorkData[3].pText}
                      dataMap={WorkData}
                      Seo={WorkData[3].SEO}
                    />
                  }
                />
                <Route
                  path="b1-english-test-skilledWorkerVisa"
                  element={
                    <GeneralLearnMoreExamPage
                      header="Work in the UK visa"
                      h1Text={WorkData[4].h3Title}
                      bookSlot={WorkData[4].bookTestLink}
                      pText={WorkData[4].pText}
                      dataMap={WorkData}
                      Seo={WorkData[4].SEO}
                    />
                  }
                />
                <Route
                  path="b1-english-test-highPotentialIndividualVisa"
                  element={
                    <GeneralLearnMoreExamPage
                      header="Work in the UK visa"
                      h1Text={WorkData[5].h3Title}
                      bookSlot={WorkData[5].bookTestLink}
                      pText={WorkData[5].pText}
                      dataMap={WorkData}
                      Seo={WorkData[5].SEO}
                    />
                  }
                />
                <Route
                  path="b1-english-test-healthCareWorkerVisa"
                  element={
                    <GeneralLearnMoreExamPage
                      header="Work in the UK visa"
                      h1Text={WorkData[6].h3Title}
                      bookSlot={WorkData[6].bookTestLink}
                      pText={WorkData[6].pText}
                      dataMap={WorkData}
                      Seo={WorkData[6].SEO}
                    />
                  }
                />
                <Route
                  path="b2-english-test-ministerOfReligionVisa"
                  element={
                    <GeneralLearnMoreExamPage
                      header="Work in the UK visa"
                      h1Text={WorkData[7].h3Title}
                      bookSlot={WorkData[7].bookTestLink}
                      pText={WorkData[7].pText}
                      dataMap={WorkData}
                      Seo={WorkData[7].SEO}
                    />
                  }
                />
                <Route
                  path="b2-english-test-innovatorFounderVisa"
                  element={
                    <GeneralLearnMoreExamPage
                      header="Work in the UK visa"
                      h1Text={WorkData[8].h3Title}
                      bookSlot={WorkData[8].bookTestLink}
                      pText={WorkData[8].pText}
                      dataMap={WorkData}
                      Seo={WorkData[8].SEO}
                    />
                  }
                />
                <Route
                  path="b2-english-test-startUpVisa"
                  element={
                    <GeneralLearnMoreExamPage
                      header="Work in the UK visa"
                      h1Text={WorkData[9].h3Title}
                      bookSlot={WorkData[9].bookTestLink}
                      pText={WorkData[9].pText}
                      dataMap={WorkData}
                      Seo={WorkData[9].SEO}
                    />
                  }
                />
              </Route>
              {/* //* study in the UK route */}
              <Route path="study">
                <Route
                  index
                  path="b1-english-test-studentVisa-belowDegreeLevel"
                  element={
                    <GeneralLearnMoreExamPage
                      header="Study in the UK visa"
                      h1Text={StudyData[0].h3Title}
                      bookSlot={StudyData[0].bookTestLink}
                      pText={StudyData[0].pText}
                      dataMap={StudyData}
                      Seo={StudyData[0].SEO}
                    />
                  }
                />
                <Route
                  index
                  path="b2-english-test-studentVisa-degreeLevel"
                  element={
                    <GeneralLearnMoreExamPage
                      header="Study in the UK visa"
                      h1Text={StudyData[1].h3Title}
                      bookSlot={StudyData[1].bookTestLink}
                      pText={StudyData[1].pText}
                      dataMap={StudyData}
                      Seo={StudyData[1].SEO}
                    />
                  }
                />
              </Route>
            </Route>
          </Route>

          <Route path="/blog">
            <Route index element={<Blog />} />
            <Route path=":slug" element={<SingleBlog />} />
          </Route>
          <Route path="/about-preston" element={<Preston />} />
          <Route path="/student-accommodation" element={<StudentAccom />} />
          <Route path="/apply-now" element={<ApplyStud />} />
          <Route path="/price-list" element={<PriceList />} />
          <Route path="/ukvi-form" element={<UKVIForm />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
