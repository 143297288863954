import React, { useState } from "react";
import "./academic.css";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import { FaGlobeAmericas } from "react-icons/fa";
import { GiNetworkBars } from "react-icons/gi";
import { IoMdTimer } from "react-icons/io";
import { VscChecklist } from "react-icons/vsc";
import { FaGraduationCap } from "react-icons/fa6";
import { FaUserGraduate } from "react-icons/fa6";
import { GiPassport } from "react-icons/gi";
import GeneralCoupon from "../../resuseable_ui/ukvi_examsPage/GeneralCoupon";
import MoreInfoCoupon from "../../couponModal/couponForMore-infoPage/MoreInfoCouponModal";
import { useNavigate } from "react-router-dom";

const Academic = () => {
  //! For the gp back router function
  let navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  const [openCouponModal, setOpenCouponModal] = useState(false);

  return (
    <>
      <Header />
      <div className="a1generalCont">
        <div className="aLearn-moreCont">
          <div className="aLearn-leftHeader">
            <h1>LanguageCert Academic</h1>

            <GeneralCoupon />
          </div>
          <div className="aLearn-rightHeader">
            <h3>Suitable for</h3>
            <div className="iconCont">
              <div className="iconCont-item">
                <FaUserGraduate className="icon-general" />
                <p>
                  Students wanting to <b>study abroad</b>
                </p>
              </div>
              <div className="iconCont-item">
                <GiPassport
                  className="icon-general"
                  style={{ fontSize: "3rem" }}
                />
                <p>
                  suitable for <b>student visa</b>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="academic-detailCont">
          <p style={{ fontSize: "2rem" }}>
            <b>Ready to take your test?</b>
          </p>

          <a
            href="https://www.languagecert.org/en/language-exams/english/languagecert-academic/academic"
            target="_blank"
            rel="noreferrer"
          >
            Book a test
          </a>
        </div>
        {/*  */}
        <div className="aLearn-expectCont">
          <div className="aLearn-expectItem">
            <div className="each-expectItemCont">
              <FaGraduationCap className="expectIcon" />

              <h5>Language focus</h5>
              <p>Academic English</p>
            </div>
            <div className="each-expectItemCont">
              <FaGlobeAmericas className="expectIcon" />

              <h5>Test availability</h5>
              <p>Worldwide</p>
            </div>
            <div className="each-expectItemCont">
              <GiNetworkBars className="expectIcon" />

              <h5>Levels </h5>
              <p>B1-C2</p>
            </div>
            <div className="each-expectItemCont">
              <IoMdTimer className="expectIcon" />

              <h5>Duration </h5>
              <p>2 hours & 34 mins</p>
            </div>
            <div className="each-expectItemCont">
              <VscChecklist className="expectIcon" />

              <h5>Official results in</h5>
              <p>5 workings days</p>
            </div>
          </div>
        </div>

        <div className="academic-sumCont">
          <h1>Exam Summary</h1>
          <p>
            Multilevel test designed to assess your ability to understand, use,
            and communicate effectively in English within an academic setting,
            including comprehending lectures, participating in discussions,
            reading and analysing academic texts, and writing research papers or
            essays.
          </p>
        </div>

        <div className="academic-btnCont">
          <a
            href="https://www.languagecert.org/en/language-exams/english/languagecert-academic/academic"
            target="_blank"
            rel="noreferrer"
          >
            Book a test
          </a>
          <button style={{ background: "var(--red-color)" }} onClick={goBack}>
            Go Back
          </button>
        </div>

        {openCouponModal && <MoreInfoCoupon closeModal={setOpenCouponModal} />}
      </div>
      <Footer />
    </>
  );
};

export default Academic;
