import React from "react";
import scrollSVG from "../../../assets/img/scroll.svg";
import { FaAngleDown } from "react-icons/fa";
import "./scrolldownDownload.css";

const ScrollDownDownload = () => {
  return (
    <a className="scrolldownDownload_cont" href="#pdfItem">
      <img
        className=" wheel"
        src={scrollSVG}
        width={10}
        height={10}
        alt="scroll btn"
      />
      <span className="scrolldownDownloadBtn_name">Scroll Down</span>
      <FaAngleDown className="scrolldownDownload_down" />
    </a>
  );
};

export default ScrollDownDownload;
