import React, { useEffect, useState } from "react";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaWhatsappSquare,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import "./home.css";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BgImage1 from "../../assets/img/bg.jpg";
import BgImage2 from "../../assets/img/bg2.jpg";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import BannerHeader from "../../components/bannerHeader/bannerheader/BannerHeader";

const Home = () => {
  //! function for displaying the banner header
  const [showBanner, setShowBanner] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowBanner(false);
    }, 10000); //* 10 seconds in milliseconds
    return () => clearTimeout(timer);
  }, []);
  //! for translation
  const { t } = useTranslation();
  // useEffect(() => {
  //   document.title = "Preston Academy of English";
  // }, []);

  // ! For each text component
  const slideText1 = (
    <>
      <div className="textImg-display">
        <p> Preston Harris Library and Museum</p>
      </div>
      <div className="hero-header">
        <h1>
          {t("Learn_h1")}{" "}
          <span className="blue-head"> {t("English_blue_h1_span")}</span> in{" "}
          <span className="red-head">Preston</span>
        </h1>
        <h1>{t("To_h1")}</h1>

        <p className="red-head">{t("Live_p")}</p>
      </div>
      <div className="hero-social">
        <a
          href="https://www.facebook.com/PrestonAcademyofEnglish"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#4267b2" }}
          className="social-icon"
        >
          <FaFacebookSquare />
        </a>
        <a
          href="https://www.instagram.com/prestonacademyofenglish/"
          target="_blank"
          rel="noreferrer"
        >
          <FaInstagramSquare className="insta-icon" />
        </a>
        <a
          href="https://x.com/Preston4English"
          target="_blank"
          rel="noreferrer"
          className="social-icon"
        >
          <FaXTwitter />
        </a>
        <a
          href="https://wa.me/441772821039"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#25d366" }}
          className="social-icon"
        >
          <FaWhatsappSquare />
        </a>
      </div>
      <div className="hero-btn">
        <NavLink to="/contact" className="btn-hero">
          {t("GetIT_btn")}
        </NavLink>
      </div>
    </>
  );
  const slideText2 = (
    <>
      <div className="hero-header">
        <h1>
          Preston <span className="red-head">Academy</span> of{" "}
          <span className="blue-head">English</span>
        </h1>
        <p>In the heart of Preston city , Lancashire.</p>
      </div>
    </>
  );
  // const slideText3 = (
  //   <>
  //     <div className="heroVid-HeadCont">
  //       <h1>
  //         Learn <span className="red-head">English</span> in
  //         <span className="blue-head">Preston</span>
  //       </h1>
  //     </div>
  //   </>
  // );

  //! for image and video loop
  const slideContents = [
    { type: "image", src: BgImage1, text: slideText1 },
    { type: "image", src: BgImage2, text: slideText2 },
    // { type: "video", src: bgVid, text: slideText3 },
  ];
  // ! slide show properties
  const properties = {
    duration: 3000, //* 10 seconds
    transitionDuration: 500,
    infinite: true,
    indicators: false,
    arrows: false,
    autoplay: true,
  };

  return (
    <>
      <div className="slideshow-cont">
        {showBanner && <BannerHeader />}
        <Slide {...properties}>
          {slideContents.map((each, index) => (
            <div key={index} className="each-slide">
              {each.type === "video" ? (
                <>
                  <video
                    src={each.src}
                    autoPlay
                    loop
                    muted
                    style={{ width: "100%" }}
                    loading="lazy"
                  />
                  <div className="dark-overlay"></div>
                  {each.text}
                </>
              ) : (
                <div
                  style={{
                    backgroundImage: `url(${each.src})`,
                    backgroundSize: "cover",
                    height: "100%",
                  }}
                  className="hero-cont"
                >
                  <div className="dark-overlay"></div>
                  {each.text}
                </div>
              )}
            </div>
          ))}
        </Slide>
      </div>
    </>
  );
};

export default Home;
