import { useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";

const MoreInfoCoupon = ({ closeModal }) => {
  //! This is to copy the coupon code func
  const [coupon] = useState("ED0B80");
  const copyToClipboard = () => {
    navigator.clipboard.writeText(coupon);
    alert("Coupon code copied to clipboard!");
  };
  return (
    <div className="coupon-modalMainCont">
      <div className="coupon-modal">
        <div
          className="closeModalButton"
          onClick={() => closeModal(false)}
          style={{ cursor: "pointer" }}
        >
          <IoMdCloseCircle />
        </div>
        <div className="coupon-modalDetail">
          <h1>
            <span className="red-head">10% off</span> all tests. Copy code below
          </h1>
          <div className="coupon">
            <span>Discount Code - {coupon}</span>
            <button onClick={copyToClipboard}>Copy Code</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreInfoCoupon;
