import React from "react";
import { Helmet } from "react-helmet";

const EnglishExamsSEO = () => {
  return (
    <Helmet>
      <title>
        UKVI & IELTS Exams - Special Discounts at Preston Academy of English
      </title>
      <meta
        name="description"
        content="Prepare for your UKVI and IELTS exams at Preston Academy of English. Get expert guidance and take advantage of our special discount codes for A1, A2, and B1 tests. Our courses are approved by the UK Home Office and designed to help you succeed in your English exams."
      />
      <meta
        name="keywords"
        content="UKVI English exams, IELTS preparation, A1 test, A2 test, B1 test, visa preparation, citizenship test, UK Home Office approved, Preston Academy of English, exam discounts, English exam discounts"
      />
      <meta
        property="og:title"
        content="UKVI & IELTS Exams - Special Discounts at Preston Academy of English"
      />
      <meta
        property="og:description"
        content="Prepare for your UKVI and IELTS exams at Preston Academy of English. Get expert guidance and take advantage of our special discount codes for A1, A2, and B1 tests. Our courses are approved by the UK Home Office and designed to help you succeed in your English exams."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.prestonacademyofenglish.com/english-test/SELT-english-tests"
      />
      <meta
        property="og:image"
        content="https://www.prestonacademyofenglish.com/og-image-ukvi.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="UKVI & IELTS Exams - Special Discounts at Preston Academy of English"
      />
      <meta
        name="twitter:description"
        content="Prepare for your UKVI and IELTS exams at Preston Academy of English. Get expert guidance and take advantage of our special discount codes for A1, A2, and B1 tests. Our courses are approved by the UK Home Office and designed to help you succeed in your English exams."
      />
      <meta
        name="twitter:image"
        content="https://www.prestonacademyofenglish.com/twitter-image-ukvi.jpg"
      />
    </Helmet>
  );
};

export default EnglishExamsSEO;
