import React, { useState } from "react";
import "./bannerheader.css";
import close from "../../../assets/img/close2.png";

const BannerHeader = () => {
  //! this function is to cancel showing the banner header
  const [visible, setVisible] = useState(true);
  const handleCloseBanner = () => {
    setVisible(false);
  };
  //! This is to copy the coupon code func
  const [coupon] = useState("ED0B80");
  const copyToClipboard = () => {
    navigator.clipboard.writeText(coupon);
    alert("Coupon code copied to clipboard!");
  };
  return (
    <>
      {visible && (
        <div className="bannerHeaderCont">
          <div className="bannerHeader-txtCont">
            <span>✅</span>
            <h2>
              Get up to <span style={{ color: "var(--red-color)" }}>10%</span>{" "}
              off for all tests
            </h2>
            <span>🔥</span>
          </div>
          <div className="bannerHeader-couponCont">
            <h3>Click to copy coupon code below 👇</h3>
            <button onClick={copyToClipboard}>ED0B80</button>
          </div>
          <button className="bannerHeader-closeBtn" onClick={handleCloseBanner}>
            <img src={close} alt="close-button" />
          </button>
        </div>
      )}
    </>
  );
};

export default BannerHeader;
