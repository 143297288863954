import React from "react";
import { Helmet } from "react-helmet";

function EnglishTestSEO() {
  return (
    <Helmet>
      <title>
        English Tests - SELT & IELTS Preparation at Preston Academy of English
      </title>
      <meta
        name="description"
        content="Ace your English tests with Preston Academy of English. Our comprehensive preparation courses for SELT and IELTS exams provide expert guidance and valuable resources. Take advantage of our special promotions and succeed in your English tests."
      />
      <meta
        name="keywords"
        content="English tests, SELT preparation, IELTS preparation, English exam courses, Preston Academy of English, A1 test, A2 test, B1 test, visa preparation, citizenship test, English exam discounts"
      />
      <meta
        property="og:title"
        content="English Tests - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        property="og:description"
        content="Ace your English tests with Preston Academy of English. Our comprehensive preparation courses for SELT and IELTS exams provide expert guidance and valuable resources. Take advantage of our special promotions and succeed in your English tests."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.prestonacademyofenglish.com/english-test"
      />
      <meta
        property="og:image"
        content="https://www.prestonacademyofenglish.com/og-image-english-test.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="English Tests - SELT & IELTS Preparation at Preston Academy of English"
      />
      <meta
        name="twitter:description"
        content="Ace your English tests with Preston Academy of English. Our comprehensive preparation courses for SELT and IELTS exams provide expert guidance and valuable resources. Take advantage of our special promotions and succeed in your English tests."
      />
      <meta
        name="twitter:image"
        content="https://www.prestonacademyofenglish.com/twitter-image-english-test.jpg"
      />
    </Helmet>
  );
}

export default EnglishTestSEO;
