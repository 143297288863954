import React from "react";
import "./ukvi.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import UkviFAQ from "./UkviFAQ";
import EnglishExamsSEO from "../../components/SEO/EnglishExamsSEO";
import { useState } from "react";
import CouponModal from "../../components/couponModal/CouponModal";
import AllCategoryExamsPage from "../../components/resuseable_ui/ukvi_examsPage/AllCategoryExamsPage";

const UKVI = () => {
  const [openCouponModal, setOpenCouponModal] = useState(false);
  //! This is to copy the coupon code func
  const [coupon] = useState("ED0B80");
  const copyToClipboard = () => {
    navigator.clipboard.writeText(coupon);
    alert("Coupon code copied to clipboard!");
  };
  return (
    <>
      <EnglishExamsSEO />
      {/* //Todo: We will be adding coupon code here when he provides it */}
      <Header />
      <div className="ukvi-generalCont">
        <h1 className="general-header">SELT English Tests</h1>
        <div className="ukvi-couponCont">
          <h1>
            10% off all <span className="red-head">tests!</span>
          </h1>
          <div className="coupon">
            <span>Discount Code - {coupon}</span>
            <button onClick={copyToClipboard}>Copy Code</button>
          </div>
        </div>
        <AllCategoryExamsPage setOpenCouponModal={setOpenCouponModal} />
        <div className="ukvi-faqCont">
          <h2>FAQ'S</h2>
          <UkviFAQ />
        </div>
        {openCouponModal && <CouponModal closeModal={setOpenCouponModal} />}
      </div>
      <Footer />
    </>
  );
};

export default UKVI;
