import "./downloadablePage.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import pdfBg from "./../../assets/img/pdfbg.png";
import ScrollDownDownload from "./scrolldownbtn/ScrollDownDownload";
import { useState, useEffect } from "react";
import client from "../../client";

const DownloadablePage = () => {
  const [pdfList, setPdfList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const query =
        '*[_type == "pdfFile"]{title, "fileUrl": file.asset->url, "previewImageUrl": previewImage.asset->url}';
      const data = await client.fetch(query);
      setPdfList(data);
    }
    fetchData();
  }, []);

  return (
    <>
      <Header />
      <div className="downloadablePage-mainCont">
        <div className="downloadablePage-detailCont">
          <img src={pdfBg} alt="pdfBG" />

          <div className="downloadablePage-detailCont-text">
            <h1>PAE Downloadable Files</h1>
            <p>
              You can download any PDF file concerning us and exam courses or
              prices
            </p>

            <ScrollDownDownload />
          </div>
        </div>

        <div className="downloadablePage-pdfCont" id="pdfItem">
          {pdfList.map((pdf, index) => (
            <div key={index} className="downloadablePage-pdfItem">
              <h3>{pdf.title}</h3>
              <img src={pdf.previewImageUrl} alt="PDF file" />
              <a
                href={`${pdf.fileUrl}`}
                download
                rel="noreferrer"
                target="_blank"
              >
                Download PDF
              </a>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DownloadablePage;
